import './App.css';
import Header from './shared/components/Header';
import { BrowserRouter } from 'react-router-dom';
import AllRoutes from './routes/index'
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <BrowserRouter>
      <Analytics />
      <Header />
      <AllRoutes />
    </BrowserRouter>
  );
}

export default App;
