import React, { Suspense } from 'react'
import Router from './Router'
import { Spinner } from 'react-bootstrap'
import { Route, Routes } from 'react-router-dom'
import ScrollToTop from '../shared/components/ScrollToTop'

function AllRoutes() {
    return (
        <>
            <ScrollToTop />
            <Routes>
                {Router?.map((route, index) => {
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            element={
                                <Suspense
                                    fallback={
                                        <div
                                            className='d-flex w-100 h-100 justify-content-center align-items-center position-absolute'
                                            style={{
                                                background: 'black',
                                            }}>
                                            <Spinner animation='border' size='lg' variant='light' />
                                        </div>
                                    }>
                                    <route.Component />
                                </Suspense>
                            }
                        />
                    )
                })}
                <Route path='*' element={<div>404</div>} />
            </Routes>
        </>
    )
}

export default React.memo(AllRoutes)