import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {route} from '../../constants/AllRoutes'

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    return (
        <>
            <header className={`header ${isMenuOpen && 'header-open'}`}>
                <div className="header_top">
                    <Link to={'/'} className="logo">yu<span>K</span>ta.</Link>
                    <button className={isMenuOpen ? 'active' : ''} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                        <span className='span1'></span>
                        <span className='span2'></span>
                        <span className='span3'></span>
                    </button>
                </div>
            </header>
            <div className={`header_menu ${isMenuOpen ? 'active' : ''}`}>
                <div className="header_grid">
                    <div className="left_grid">
                        <ul>
                            <li><Link to={route.mainPage} onClick={() => setIsMenuOpen(false)}>Home</Link></li>
                            <li><Link to={route.services} onClick={() => setIsMenuOpen(false)}>Services</Link></li>
                            <li><Link to={route.project} onClick={() => setIsMenuOpen(false)}>Project</Link></li>
                            {/* <li><Link onClick={() => setIsMenuOpen(false)}>Team</Link></li> */}
                            <li><Link to={route.contact} onClick={() => setIsMenuOpen(false)}>Contact</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header