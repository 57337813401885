import { lazy } from "react";
import { route } from "../shared/constants/AllRoutes";

// MAIN PAGE
const MainPage = lazy(() => import('../views/mainPage/index'))

// SERVICES PAGE
const ServicesPage = lazy(() => import('../views/servicesPage/index'))
const DetailServicesPage = lazy(() => import('../views/servicesPage/detail/index'))

// TEAM PAGE
const TeamPage = lazy(() => import('../views/teamPage/index'))
const DetailTeamPage = lazy(() => import('../views/teamPage/details/index'))

// PROJECT PAGE
const ProjectPage = lazy(() => import('../views/projectPage/index'))
const DetailProjectPage = lazy(() => import('../views/projectPage/detail/index'))

// CONTACT PAGE
const ContactPage = lazy(() => import('../views/contact/index'))

const RoutesDetails = [
    // MAIN PAGE
    { path: route.mainPage, Component: MainPage },

    // SERVICES PAGE
    { path: route.services, Component: ServicesPage },
    { path: route.viewServices(':id'), Component: DetailServicesPage },

    // TEAM PAGE
    { path: route.profile, Component: TeamPage },
    { path: route.viewProfile(':id'), Component: DetailTeamPage },

    // PROJECT PAGE
    { path: route.project, Component: ProjectPage },
    { path: route.viewProject(':id'), Component: DetailProjectPage},

    // CONTACT PAGE
    { path: route.contact, Component: ContactPage}
]

export default RoutesDetails