export const route = {
    mainPage: '/',

    project: '/project',
    viewProject: (id) => `/project/${id}`,

    profile: '/profile',
    viewProfile: (id) => `/profile/${id}`,

    services: '/services',
    viewServices: (id) => `/services/${id}`,

    contact: '/contact',
}